jQuery(function ($) {
    var $win = $(window),
        $nav = $('#Gnav'),
        navHeight = $nav.outerHeight(),
        footerHeight = $('footer').outerHeight(),
        docmentHeight = $(document).height(),
        navPos = $nav.offset().top,
        showClass = 'is-show';
    $win.on('load scroll', function () {
        var value = $(this).scrollTop(),
            scrollPos = $win.height() + value;

        if (value > 800) {
            $nav.addClass(showClass);
        } else {
            $nav.removeClass(showClass);
        }

        // if (value > 1000) {
        //     if (docmentHeight - scrollPos <= footerHeight) {
        //         $nav.removeClass(showClass);
        //     } else {
        //         $nav.addClass(showClass);
        //     }
        // } else {
        //     $nav.removeClass(showClass);
        // }
    });
});